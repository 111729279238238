import styled from 'styled-components';
import React, { useState } from 'react';
import { useNavigate } from 'poly-client-routing';
import { getThemeColor } from 'poly-book-admin';

import { Icon } from '../../icons/Icon.js';
import { Button } from '../../components/Button.js';
import { projectFilterFormId } from './constants.js';
import { ProjectFilterForm } from './ProjectFilterForm.js';

const ProjectFilterScreenWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  padding: 24px;
`;

const ProjectFilterScreenSectionS = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProjectFilterFullScreenSectionS = styled(ProjectFilterScreenSectionS)`
  height: 100%;
`;

const ProjectFilterScreenTitleS = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  color: #12347a;
  margin: 20px 0 15px 0;
`;

const ShowResultsButtonS = styled(Button)`
  margin-top: 8px;

  svg {
    margin-right: 10px;
  }
`;

const ResetFilterButtonS = styled(Button)`
  background: transparent;
  color: ${getThemeColor(['secondaryDark'])};
  border: 1px solid ${getThemeColor(['secondaryDark'])};

  svg {
    margin-right: 10px;
    width: 12px;
    height: 12px;

    path {
      fill: ${getThemeColor(['secondaryDark'])};
    }
  }
`;

export function ProjectFilterScreen() {
  const navigate = useNavigate();
  const [triggerReset, setTriggerReset] = useState(false);

  const onCloseFilter = () => navigate(-1);

  return (
    <ProjectFilterScreenWrapperS>
      <ProjectFilterFullScreenSectionS>
        <Icon name="close" onClick={onCloseFilter} />
        <ProjectFilterScreenTitleS>Filter</ProjectFilterScreenTitleS>
        <ProjectFilterForm
          {...{ triggerReset, setResetTrigger: setTriggerReset }}
        />
      </ProjectFilterFullScreenSectionS>
      <ProjectFilterScreenSectionS>
        <ShowResultsButtonS
          type="submit"
          iconName="rocket"
          caption="Show results"
          form={projectFilterFormId}
        />
        <ResetFilterButtonS
          iconName="close"
          caption="Reset filter"
          onClick={() => setTriggerReset(true)}
        />
      </ProjectFilterScreenSectionS>
    </ProjectFilterScreenWrapperS>
  );
}
