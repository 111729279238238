import styled from 'styled-components';
import { bool, func } from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { debounce, insertParamsIntoURL } from 'poly-utils';
import { CREATE_ASSET_PERMISSION } from 'poly-security';
import { useNavigate } from 'poly-client-routing';
import { getThemeColor } from 'poly-book-admin';

import { Scanner } from './Scanner.js';
import { routes } from '../../routes.js';
import { EnterCode } from './EnterCode.js';
import { AddNewConfirm } from './AddNew.js';
import { FlexColumnCentered } from '../../components/Containers.js';
import { QR_CODE_SCANNER_STATUSES, isAssetInactive } from './common.js';
import { useAssetByQrCodeQuery } from './hooks.js';
import { useNetworkStatus } from '../../providers/NetworkStatusProvider.js';

const ScreenContainer = styled(FlexColumnCentered)`
  width: 100%;
  height: 100%;
  position: relative;
`;

const OverlayContainer = styled(FlexColumnCentered)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 32px;
  background: ${({ shaded }) => shaded && 'rgba(0, 0, 0, 0.5)'};
  z-index: 2;
`;

const TabsContainer = styled.div`
  width: 100%;
  height: 42px;
  background: #fff;
  border-radius: 100px;
  padding: 4px;
  display: flex;
  z-index: 2;
`;

const Tab = styled.div`
  display: flex;
  flex-grow: 1;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 100px;
  background: ${getThemeColor(['midLight'])};
  background-color: ${({ isActive }) => (isActive ? '#EBEBEB' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#1A1514' : '#9197AC')};
`;

// parseQrCodeId :: String -> String
const parseQrCodeId = (scanText) => {
  const result = scanText.match(/^https?:\/\/.+\/a\/([a-zA-Z0-9]{6})$/);
  return result?.[1];
};

function SwitchTabs({ scanning, startScanning, stopScanning }) {
  return (
    <TabsContainer>
      <Tab isActive={scanning} onClick={startScanning}>
        Scan Code
      </Tab>
      <Tab isActive={!scanning} onClick={stopScanning}>
        Enter Code
      </Tab>
    </TabsContainer>
  );
}

SwitchTabs.propTypes = {
  scanning: bool.isRequired,
  startScanning: func.isRequired,
  stopScanning: func.isRequired,
};

export const useHasPermissionToCreateAsset = () => {
  const allowed = useHasUserAccessWithPermission(CREATE_ASSET_PERMISSION);
  return allowed;
};

export function ScanQrCodeScreen() {
  const navigate = useNavigate();
  const [scanning, setScanning] = useState(true);
  const [status, setStatus] = useState();
  const [scanText, setScanText] = useState('');
  const [qrCodeId, setQrCodeId] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const { isOnline } = useNetworkStatus();

  const canCreateAsset = useHasPermissionToCreateAsset();

  const assetQueryResult = useAssetByQrCodeQuery(qrCodeId);

  const stopScanning = () => {
    if (!isDisabled) {
      setScanning(false);
    }
  };

  const startScanning = () => {
    setScanning(true);
    setIsDisabled(true);
  };

  const resetScanner = () => {
    setScanText('');
    setQrCodeId('');
    setStatus(null);
  };

  const navigateDebounced = useCallback(debounce(1000)(navigate), []);

  const navigateByScanning = (url) =>
    scanning ? navigateDebounced(url) : navigate(url);

  const handleIncorrect = (isExists = false) => {
    setStatus(QR_CODE_SCANNER_STATUSES.incorrect);

    const scanQrCodeErrorUrl = `${routes.scanQrCodeError}?isExists=${isExists}`;

    return scanning
      ? navigateDebounced(scanQrCodeErrorUrl)
      : navigate(scanQrCodeErrorUrl);
  };

  useEffect(() => {
    if (scanText) {
      const parsedQrOceId = parseQrCodeId(scanText);

      if (parsedQrOceId) {
        setQrCodeId(parsedQrOceId);
      }

      if (!parsedQrOceId) {
        handleIncorrect();
      }
    }
  }, [scanText]);

  const isAssetLoadedByQRCode = qrCodeId && !assetQueryResult.loading;
  const assetWasFound = assetQueryResult.asset && isOnline;
  const isAssetNewOne = !assetQueryResult?.asset?._id && canCreateAsset;
  const isAssetIncorrect = assetQueryResult.asset && !isOnline;

  useEffect(() => {
    if (isAssetLoadedByQRCode) {
      if (assetWasFound) {
        setStatus(QR_CODE_SCANNER_STATUSES.found);

        const createProjectUrl = insertParamsIntoURL(
          { assetQrCodeId: qrCodeId },
          routes.createProject,
        );

        if (isAssetInactive(assetQueryResult.asset)) {
          const scanQrCodeInactiveUrl = insertParamsIntoURL(
            { assetQrCodeId: qrCodeId },
            routes.scanQrCodeInactive,
          );

          return navigateByScanning(scanQrCodeInactiveUrl);
        }

        return navigateByScanning(createProjectUrl);
      }

      if (isAssetNewOne) {
        return setStatus(QR_CODE_SCANNER_STATUSES.new);
      }

      if (isAssetIncorrect) {
        return handleIncorrect(true);
      }

      return handleIncorrect();
    }
    return () => {};
  }, [qrCodeId, assetQueryResult]);

  const isNewCode = status === QR_CODE_SCANNER_STATUSES.new;

  return (
    <ScreenContainer>
      <OverlayContainer shaded={scanning}>
        <SwitchTabs
          scanning={scanning}
          startScanning={startScanning}
          stopScanning={stopScanning}
        />
        {!scanning && (
          <EnterCode
            setQrCodeId={setQrCodeId}
            loading={assetQueryResult.loading}
          />
        )}
        {isNewCode && (
          <OverlayContainer shaded>
            <AddNewConfirm assetQrCodeId={qrCodeId} onClose={resetScanner} />
          </OverlayContainer>
        )}
      </OverlayContainer>
      {scanning && (
        <Scanner
          pause={isNewCode}
          onClose={stopScanning}
          setScanText={setScanText}
          setIsDisabled={setIsDisabled}
          status={status}
        />
      )}
    </ScreenContainer>
  );
}
